// import './App.css';
import HomePage from './components/HomePage';

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;