import React from 'react';
import { Card, Row, Col } from 'antd';

const HomePage = () => {

    const projectData = [
        {appName: "Sonday System", imageUrl: "", appUrl: ""},
        {appName: "Salesforce Sidekick", imageUrl: "", appUrl: ""},
        {appName: "Polybot", imageUrl: "", appUrl: ""},
        {appName: "RFP generator", imageUrl: "", appUrl: ""},
        {appName: "Assessment generator", imageUrl: "", appUrl: ""},
        {appName: "AWS graded decodable reader", imageUrl: "", appUrl: ""},
        {appName: "Short-writing grading assistant", imageUrl: "", appUrl: ""},
        {appName: "Essay grading assistant", imageUrl: "", appUrl: ""},
        {appName: "Rubric generator", imageUrl: "", appUrl: ""},
    ];
    
    return (
        <>
            <h1>Welcome to AICC Labs Dashboard</h1>
            <p style={{ fontStyle: 'italic' }}>Here you can find all AICC projects</p>
            <Row gutter={16}>
                {projectData.map((project, index) => (
                    <Col key={index} span={5}>
                        <Card
                            hoverable
                            style={{ width: 240 }}
                            title={project.appName}
                            cover={<img src={project.imageUrl || 'defaultImageURL'} alt="Not available" style={{ height: 100, backgroundColor: '#f0f2f5' }} />}
                            onClick={() => window.location.href = project.appUrl}
                        >
                        </Card>
                        <br />
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default HomePage;